import { defer } from "react-router-dom";
import { client } from "../../client"; // Import your Sanity client and urlFor function

async function loadProductDetails(productid) {
  const response = await client.fetch(
    `
      *[_type == 'products' && _id == "${productid}"][0] {
        _id,
        title,
        categories -> {
          title
        },
        description,
        karakteristika,
        karakteristikatfunksionale,
        perberja,
        productImages[] {
          asset-> {
            _id,
            url
          }
        }
      }
    `,
  );
  if (!response) throw new Error(`No response for slug ${productid}`);
  const resData = response;
  return resData;
}

export async function loaderProduct({ request, params }) {
  const productId = params.productid;
  return defer({
    product: await loadProductDetails(productId),
  });
}
