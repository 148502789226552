// api.js

import { defer } from "react-router-dom";
import { client } from "../../client"; // Import your Sanity client

async function loadApplicationTypeById(applicationTypeId) {
  try {
    const response = await client.fetch(
      `
      *[_type == 'types' && _id == $applicationTypeId][0] {
        _id,
        title,
        slug,
        application,
        tipi,
        body,
         applicationtypeImage[]{
          title,
          description,
          asset->{url, metadata}
        }
      }
    `,
      { applicationTypeId },
    );
    if (!response) {
      throw new Error(
        `No response for application type ID ${applicationTypeId}`,
      );
    }

    return response;
  } catch (error) {
    // Handle error
    console.error(`Error loading application type details: ${error.message}`);
    throw error;
  }
}
export async function loadProductsByType(typeId) {
  const products = await client.fetch(
    `
    *[_type == 'products' && references(types, "${typeId}")] {
      _id,
      title,
      description,
      karakteristika,
      karakteristikatfunksionale,
      perberja,
      productImages[] {
        asset-> {
          _id,
          url
        }
      },
      'category': categories-> 
    slug.current
      
    }
  `,
    { types: typeId }, // Use "types" as the parameter name
  );

  return products;
}

export async function loaderType({ request, params }) {
  const typeId = params.typeId;
  return defer({
    type: await loadApplicationTypeById(typeId),
  });
}
