// TODO: Add NavLink functionality
// import { NavLink } from 'react-router-dom';

import "./navbar.css";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import LogoThermal from "../logo";
import { Link } from "react-router-dom";
function MainNavigation() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isNavHidden, setIsNavHidden] = useState(false); // Track whether the navbar should be hidden
  const [isNavMargin, setIsNavMargin] = useState(false); // Track whether the navbar should have a margin
  const [prevScrollY, setPrevScrollY] = useState(0); // Track the previous scroll position
  const [isAplikimetDropdownOpen, setIsAplikimetDropdownOpen] = useState(false);
  const [isProdukutetDropdownOpen, setIsProduktetDropdownOpen] =
    useState(false);

  const aplikimetDropdownRef = useRef(null);
  const produkutetDropdownRef = useRef(null);
  const location = useLocation(); // Get the current location from React Router

  const toggleAplikimetDropdown = () => {
    setIsAplikimetDropdownOpen(!isAplikimetDropdownOpen);
    setIsProduktetDropdownOpen(false);
  };

  const toggleProduketDropdown = () => {
    setIsProduktetDropdownOpen(!isProdukutetDropdownOpen);
    setIsAplikimetDropdownOpen(false);
  };
  useEffect(() => {
    // Close the mobile menu when the location changes (e.g., when a NavLink is clicked)
    setIsNavExpanded(false);
  }, [location.pathname]); // Listen to changes in the pathname
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrollingDown = currentScrollY > prevScrollY;

      // Hide the navbar when scrolling down
      setIsNavHidden(isScrollingDown && currentScrollY > 50);

      if (currentScrollY > 55) {
        // Apply a margin of -40px when the condition is met
        setIsNavMargin(true);
      } else {
        // Remove the margin when the condition is not met
        setIsNavMargin(false);
      }

      // Reveal the navbar when scrolling all the way up
      if (!isScrollingDown && currentScrollY === 0) {
        setIsNavHidden(false);
      }

      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        aplikimetDropdownRef.current &&
        !aplikimetDropdownRef.current.contains(event.target)
      ) {
        setIsAplikimetDropdownOpen(false);
      }
      if (
        produkutetDropdownRef.current &&
        !produkutetDropdownRef.current.contains(event.target)
      ) {
        setIsProduktetDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <nav
      className={`navigation ${isNavHidden ? "hidden-navbar" : ""} ${
        isNavMargin ? "sticky " : ""
      }`}
    >
      <div className='navigation-items'>
        <a
          href={"/"}
          className='brand-cart cursor-none md:cursor-pointer   select-none outline-none'
        >
          <LogoThermal />
          <span className='item brand-name text-xs font-montserrat font-normal select-none outline-none '>
            Thermal Technology Albania
          </span>
        </a>

        <button
          className='hamburger '
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          {/* icon from heroicons.com */}
          {!isNavExpanded ? (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              viewBox='0 0 20 20'
              fill='white'
            >
              <path
                fillRule='evenodd'
                d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z'
                clipRule='evenodd'
              />
            </svg>
          ) : (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              color='white'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-6 h-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 18L18 6M6 6l12 12'
              />
            </svg>
          )}
        </button>
        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <ul className='list'>
            <li>
              <a className='item' href='/'>
                Home
              </a>
            </li>

            <li
              onClick={toggleProduketDropdown}
              ref={produkutetDropdownRef}
              className='relative block lg:cursor-pointer'
            >
              <p className='font-semibold  max-md:py-[1.5rem] gap-1 flex justify-center  pl-2 items-center'>
                Produktet{" "}
                {!isProdukutetDropdownOpen ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='w-5 h-5'
                  >
                    <path
                      fillRule='evenodd'
                      d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
                      clipRule='evenodd'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='w-5 h-5'
                  >
                    <path
                      fillRule='evenodd'
                      d='M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z'
                      clipRule='evenodd'
                    />
                  </svg>
                )}
              </p>

              {isProdukutetDropdownOpen && (
                <ul className='relative inline-block animate-fadeInSlider md:absolute w-full text-left left-0 z-10 mt-2 md:w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  <li
                    className='py-1 inline-block  relative w-full z-20 font-semibold text-left max-md:bg-gray-400  '
                    role='menuitem'
                  >
                    <NavLink
                      to='/categories/ngrohje-ne-dysheme-interior'
                      className='text-gray-700 inline-block z-10 max-md:border-b-2 md:hover:text-white selection:text-black  px-4 py-2 text-sm text-left max-md:text-center '
                      role='menuitem'
                    >
                      Ngrohje në dysheme të brendshme
                    </NavLink>
                    <NavLink
                      to='/categories/ngrohje-ne-dysheme-exterior'
                      className='text-gray-700 inline-block max-md:border-b-2 md:hover:text-white px-4 py-2 text-sm text-left max-md:text-center'
                      role='menuitem'
                    >
                      Ngrohje ne dysheme të jashtme
                    </NavLink>
                    <NavLink
                      to='/categories/mur-dhe-tavan'
                      className='text-gray-700 inline-block max-md:border-b-2 md:hover:text-white px-4 py-2 text-sm text-left max-md:text-center'
                      role='menuitem'
                    >
                      Ngrohje ne mur dhe tavan
                    </NavLink>
                    <NavLink
                      to='/categories/radiatore'
                      className='text-gray-700 inline-block max-md:border-b-2 md:hover:text-white px-4 py-2 text-sm text-left max-md:text-center'
                      role='menuitem'
                    >
                      Radiatore
                    </NavLink>
                    <NavLink
                      to='/categories/ngrohje-per-kisha-xhami-dhe-muze'
                      className='text-gray-700 max-md:border-b-2 md:hover:text-white inline-block px-4 py-2 text-sm text-left max-md:text-center'
                      role='menuitem'
                    >
                      Ngrohje per kisha, xhami dhe muze
                    </NavLink>

                    <NavLink
                      to='/categories/shkrires-bore-dhe-akulli'
                      className='text-gray-700 inline-block max-md:border-b-2 md:hover:text-white px-4 py-2 text-sm text-left max-md:text-center'
                      role='menuitem'
                    >
                      Shkrires bore dhe akulli
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li
              ref={aplikimetDropdownRef}
              onClick={toggleAplikimetDropdown}
              className='relative  lg:cursor-pointer '
            >
              <p className='font-semibold max-md:py-[1.5rem] flex justify-center gap-1 pl-2 items-center '>
                Aplikimet{" "}
                {!isAplikimetDropdownOpen ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='w-5 h-5'
                  >
                    <path
                      fillRule='evenodd'
                      d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
                      clipRule='evenodd'
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 20 20'
                    fill='currentColor'
                    className='w-5 h-5'
                  >
                    <path
                      fillRule='evenodd'
                      d='M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z'
                      clipRule='evenodd'
                    />
                  </svg>
                )}
              </p>

              {isAplikimetDropdownOpen && (
                <ul
                  className='absolute animate-fadeInSlider max-md:relative max-md:w-full text-left left-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                  role='menu'
                  aria-orientation='vertical'
                  aria-labelledby='menu-button'
                  tabIndex='-1'
                >
                  <li
                    className='py-1 font-semibold text-white text-left max-md:bg-gray-400'
                    role='none'
                  >
                    <NavLink
                      to='/applications/shtepi'
                      className='text-gray-700 max-md:border-b-2 md:hover:text-white   block px-4 py-2 text-sm text-left max-md:text-center '
                      role='menuitem'
                    >
                      Shtepi
                    </NavLink>
                    <NavLink
                      to='/applications/komerciale'
                      className='text-gray-700 block px-4 md:hover:text-white max-md:border-b-2 py-2 text-sm text-left max-md:text-center'
                      role='menuitem'
                    >
                      Komerciale
                    </NavLink>
                    <NavLink
                      to='/applications/industriale'
                      className='text-gray-700 block md:hover:text-white max-md:border-b-2 px-4 py-2 text-sm text-left max-md:text-center'
                      role='menuitem'
                    >
                      Industriale
                    </NavLink>
                    <NavLink
                      to='/applications/ngrohje'
                      className='text-gray-700 block md:hover:text-white px-4 py-2 text-sm text-left max-md:text-center'
                      role='menuitem'
                    >
                      Ngrohje per kisha, xhami dhe muze
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <NavLink className='item' to='/about'>
                Rreth Nesh
              </NavLink>
            </li>
            <li>
              <NavLink className='item' to='/contact-mail'>
                Me shume
              </NavLink>
            </li>
          </ul>
        </div>
        <div className='navigation-button'>
          <NavLink to='/contact-us'>
            <button>Na Kontaktoni</button>
          </NavLink>
        </div>
      </div>
    </nav>
  );
}

export default MainNavigation;
