import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import MainNavigation from "../components/Layout/MainNavigation";
import Footer from "../components/Layout/Footer";
import Loader from "../components/UI/Loader";

function RootLayout() {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 600);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className='w-full animate-fade flex flex-col min-h-screen justify-between '>
          <MainNavigation />

          <Outlet />

          <Footer />
        </div>
      )}
    </>
  );
}

export default RootLayout;
