import { defer } from 'react-router-dom';
import { client } from '../../client'; // Import your Sanity client and urlFor function


async function loadCategory(slug) {
    const response = await client
    .fetch(
      `
      *[_type == 'category' && slug.current == "${slug}"][0] {
        _id,
        title,
        description,
        advantages,
        image {
          asset-> {
            _id,
            url
          }
        }
      }
    `
    );
    if (!response || response === null) {
      throw new Error(`No response for slug ${slug}`);
    }      const resData =  response;
      return resData;
    }

    

    export async function loadProductsByCategory(categoryId) {
      const products = await client.fetch(
        `
        *[_type == 'products' && references("category", "${categoryId}")] {
          _id,
          title,
          description,
          karakteristika,
          karakteristikatfunksionale,
          perberja,
          productImages[] {
            asset-> {
              _id,
              url
            }
          }
        }
      `,
        { categoryId }
      );
    
      return products;
    }
  
  
  export async function loader({request,params}) {
    const categoryId= params.category
    return defer({
      category:await  loadCategory(categoryId),
    });
  }
  
  