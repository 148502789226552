import { useEffect, useRef } from "react";
import "./style.css";
const MapSistemeNgrohje = () => {
  const mapContainerRef = useRef(null);

  const renderMap = () => {
    // Array of coordinates for multiple locations
    const locations = [
      { lat: 41.32245955417901, lng: 19.81273055346029, title: "SistemNgrohje" },
   
      // Add more locations as needed
    ];
  
    // Create map instance
    const map = new window.google.maps.Map(mapContainerRef.current, {
      zoom: 14,
      center: locations[0], // Set the center to the first location
    });
  
    // Add markers for each location
    locations.forEach(location => {
      const marker = new window.google.maps.Marker({
        position: location,
        map: map,
        title: location.title,
      });
  
      // Add click event listener to open Google Maps
      marker.addListener('click', () => {
        const mapUrl = `https://www.google.com/maps?q=${location.lat},${location.lng}`;
        window.open(mapUrl, '_blank');
      });
    });
  };
  




  useEffect(() => {
    const googleChecker = () => {
        if (!window.google) {
          setTimeout(googleChecker, 100);
          console.log("not there yet");
        } else {
          renderMap();
        }
      };
    googleChecker();
  }, []); // Empty dependency array ensures that this effect runs only once

  return (
    <div className='map-holder'>
      <div className='map-container' ref={mapContainerRef} />
    </div>
  );
};

export default MapSistemeNgrohje;
