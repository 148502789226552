import React from 'react'
import ContactForm from '../components/contact-us'

const ContactUs = () => {
  return (
    <div>
      <ContactForm />
    </div>
  )
}

export default ContactUs
