import React from "react";

const ContactForm = () => {
  return (
    <div className='container my-24 mx-auto md:px-6'>
      <section className='bg-gray-100'>
        <div className='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8'>
          <div className='max-w-2xl lg:max-w-4xl mx-auto text-center'>
            <h2 className='text-3xl font-extrabold text-gray-900'>
              Vizitoni dyqanin
            </h2>
            <p className='mt-4 text-lg text-gray-500'>
              Per me shume informacion rreth produkteve vizitoni dyqanin
            </p>
          </div>
          <div className='mt-16 lg:mt-20'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              <div className='rounded-lg overflow-hidden'>
                <iframe
                  src='https://maps.google.com/maps?q=41.32245955417901,19.81273055346029&t=&z=13&ie=UTF8&iwloc=&output=embed'
                  className='left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg'
                  title='SistemeNgrohje'
                  allowFullScreen
                ></iframe>
              </div>
              <div>
                <div className='max-w-full mx-auto rounded-lg overflow-hidden'>
                  <div className='px-6 py-4'>
                    <h3 className='text-lg font-medium text-gray-900'>
                      Adresa jonë
                    </h3>
                    <p className='mt-1 text-gray-600'>
                      Rruga Bulevardi Bajram Curri ,Thermal Technology
                    </p>
                  </div>
                  <div className='border-t border-gray-200 px-6 py-4'>
                    <h3 className='text-lg font-medium text-gray-900'>Hours</h3>
                    <p className='mt-1 text-gray-600'>
                      Monday - Saturday: 9:00am - 14:00pm , 16:30pm - 20:00pm
                    </p>
                  </div>
                  <div className='border-t border-gray-200 px-6 py-4'>
                    <h3 className='text-lg font-medium text-gray-900'>
                      Contact
                    </h3>
                    <p className='mt-1 text-gray-600'>althermaltt@yahoo.com</p>
                    <p className='mt-1 text-gray-600'>
                      Phone: +355 68 902 6474
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactForm;
