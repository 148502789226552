import React from 'react'
import AboutUsPage from '../components/about-us'
import MapSistemeNgrohje from '../components/map'

const AboutUs = () => {
  return (
    <div>
      <AboutUsPage />

      <MapSistemeNgrohje />
    </div>
  )
}

export default AboutUs
