import React, { useState } from "react";

function MailPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = () => {
    // Construct the mailto link
    window.location.href = `mailto:althermaltt@yahoo.com?subject=%20${formData.subject}&body=Name:%20${formData.name}%0D%0AEmail:%20${formData.email}%0D%0AMessage:%20${formData.message}`;

    // Redirect to the mailto link
  };

  return (
    <>
      <div className='w-full flex items-center justify-center relative h-full mt-40 overflow-hidden animate-fadeUp  mb-10'>
        <div className='relative  bg-white shadow rounded py-12 lg:px-28 px-8'>
          <p className='md:text-3xl text-xl font-bold leading-7 text-center text-gray-700'>
            Na kontaktoni per me shume
          </p>
          <div className='md:flex items-center mt-12'>
            <div className='md:w-72 flex flex-col'>
              <label className='text-base font-semibold leading-none text-gray-800'>
                Emri
              </label>
              <input
                name='name'
                type='text'
                value={formData.name}
                onChange={handleInputChange}
                className='text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100'
                placeholder='Please input name'
              />
            </div>
            <div className='md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4'>
              <label className='text-base font-semibold leading-none text-gray-800'>
                Email
              </label>
              <input
                name='email'
                type='text'
                value={formData.email}
                onChange={handleInputChange}
                className='text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100'
                placeholder='Please input email address'
              />
            </div>
          </div>
          <div className='md:flex items-center mt-12'>
            <div className='w-full flex flex-col  md:mt-0 mt-4'>
              <label className='text-base font-semibold leading-none text-gray-800'>
                Subject
              </label>
              <input
                name='subject'
                type='text'
                value={formData.subject}
                onChange={handleInputChange}
                className='text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100'
                placeholder='Please input subject'
              />
            </div>
          </div>
          <div>
            <div className='w-full flex flex-col mt-8'>
              <label className='text-base font-semibold leading-none text-gray-800'>
                Mesazhi
              </label>
              <textarea
                name='message'
                onChange={handleInputChange}
                value={formData.message}
                tabIndex={0}
                aria-label='leave a message'
                type='text'
                className='h-36 text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 resize-none'
                defaultValue={""}
                placeholder='Please input your message'
              />
            </div>
          </div>
          <p className='text-xs leading-3 text-gray-600 mt-4'>
            By clicking submit you agree to our terms of service, privacy policy
            and how we use data as stated
          </p>
          <div className='flex items-center justify-center w-full'>
            <button
              onClick={handleSubmit}
              className='mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-[#FFBF69] rounded hover:bg-orange-400 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 focus:outline-none'
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MailPage;
