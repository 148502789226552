import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
  projectId: "8agmyodd",
  dataset: "production",
  useCdn: false,
  apiVersion: "2023-09-04", // use a UTC date string
});

const builder = imageUrlBuilder(client);
export const urlFor = (source) => builder.image(source);
