import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./index.css";

import Error from "./pages/Error";
import RootLayout from "./pages/RootLayout";
import { loaderProduct } from "./api/product-api";
import { loader as categoryLoader } from "./api/category-api";
import { loader as applicationLoader } from "./api/application-api";
import { loaderType } from "./api/type-api";
import { Suspense, lazy } from "react";
import Loader from "./components/UI/Loader";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import MailPage from "./pages/Mail";

const LazyApplicationItem = lazy(() => import("./pages/Applications"));
const LazyHomePage = lazy(() => import("./pages/Homepage"));
const CategoryPage = lazy(() => import("./pages/Category"));
const TypesPage = lazy(() => import("./pages/Types"));
const ProductPage = lazy(() => import("./pages/Product"));




const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error />,
    children: [
      { index: true, element: <LazyHomePage /> ,    errorElement: <Error />,
    },
      {
        path: "categories/:category",
        id: "category-data",
        loader: categoryLoader,
        children: [
          { index: true, element: <CategoryPage /> ,     errorElement: <Error />,        },
          {
            path: "products/:productid",
            element: <ProductPage />,
            id: "product-data",
            loader: loaderProduct,
          },
        ],
      },

      {
        path: "applications/:application",
        id: "application-data",
        loader: applicationLoader,
        children: [
          { index: true, element: <LazyApplicationItem /> ,    errorElement: <Error />,
        },
          {
            path: "types/:typeId",
            id: "type-data",
            element: <TypesPage />,
            loader: loaderType,
            errorElement: <Error />,

          },
        ],
      },
      {
        path: "about", element:<AboutUs /> ,    errorElement: <Error />,

      },
      {
        path: "contact-us", element:<ContactUs /> ,     errorElement: <Error />,

      },
      {
        path: "contact-mail", element:<MailPage /> ,     errorElement: <Error />,

      }
    ],
  },
]);

router.enableScrollRestoration("auto"); // or 'top' for scrolling to the top

function App() {
	

  return (
    <RouterProvider router={router}>
      <Suspense fallback={<Loader />}>
        <RootLayout />
      </Suspense>
    </RouterProvider>
  );
}

export default App;
