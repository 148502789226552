import { defer, json } from 'react-router-dom';
import { client } from '../../client'; // Import your Sanity client and urlFor function

// Function to load application data by slug
async function loadApplicationData(slug) {
	// Introduce an artificial delay of 2 seconds (adjust as needed)

	const response = await client.fetch(
		`
    *[_type == 'applications' && slug.current == $slug][0] {
      _id,
      title,
      description,
      information,
      'imageUrls': applicationimages[].asset->url
    }
  `,
		{ slug }
	);

	if (!response)
		throw json(
			{ message: 'Could not fetch application.' },
			{
				status: 500,
			}
		);
	return response;
}

export async function loadApplicationTypesByApplication(applicationId) {
	const applicationTypes = await client.fetch(
		`
    *[_type == 'types' && references("application", $applicationId)] {
      _id,
      title,
      slug,
      tipi,
      'images': applicationtypeImage[]{
        title,
        description,
        asset->{url, metadata}
      }
    }
    `,
		{ applicationId }
	);
	if (!applicationTypes)
		throw new Error(`No response for application id: ${applicationId}`);
	return applicationTypes;
}
//TODO : Load application types by application using defer

// Loader function for the route
export async function loader({ request, params }) {
	const applicationSlug = params.application;

	// Load application data
	const applicationData = await loadApplicationData(applicationSlug);

	return defer({
		application: applicationData,
	});
}
