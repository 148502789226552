import React from "react";
import "./style.css";
import { AiOutlineRight } from "react-icons/ai";
import PrimaryButton from "../../components/UI/PrimaryButton";
import modernHouse from "../../assets/modernHouses.jpg"
import aboutUsSecondImage from "../../assets/houseImage.jpg"
import { Link } from "react-router-dom";
const AboutUsPage = () => {
  return (
    <main>
      <section className='about-us-header '>
        <p className="font-myFont">Rreth Nesh</p>
      </section>

      <section className='about-us-navigator'>
        <span>
          {" "}
          Home <AiOutlineRight size={22} />
        </span>{" "}
        <span>Rreth Nesh</span>
      </section>

      <section className='about-us-history'>
        <div className='about-us-history-container'>
          <div className='history-img'>
            <img
              src={modernHouse}
              alt=''
            />
          </div>

          <div className='history-text'>
            <h3>NE SHQIPERI QE NE 2010</h3>

            <p className="font-montserrat">
              Teknologjia jonë e fibrave të karbonit përdor një kabllo që
              konsumon më pak energji elektrike se kabllot e tjera dhe nxehet më
              shpejt: një radiator elektrik i teknologjisë termike rrit
              temperaturën e dhomës nga 10°C në 23°C (nga 50°F në 73,4°F) në
              03h. :19' kundrejt 04h:30' të një radiatori me rezistorë bakri
              (Applus Laboratories 2013). Teknologjia e fibrave të karbonit
              garanton prodhimin e sistemeve inovative të ngrohjes elektrike me
              konsum të ulët, me kursime energjie prej më shumë se 25%.
            </p>
          </div>
        </div>
        <div className='about-us-goal-container'>
          <div className='history-img'>
            <img
              src={aboutUsSecondImage}
              alt=''
            />
          </div>

          <div className='history-text'>
            <h3>Shëndeti dhe Mirëqënia</h3>

            <p className="font-montserrat">
              Teknologjia termike prodhon sisteme ngrohjeje me fibra karboni me
              anë të rrezatimit, duke shmangur kështu krijimin e lëvizjeve
              konvektive dhe rrjedhimisht qarkullimin e pluhurit dhe marimangave
              në mjedis. Kablloja e karbonit konsiderohet se përputhet me
              kufijtë ligjorë në lidhje me emetimet e fushës elektromagnetike
              (Elettra80 - TUV Laboratorio 2010) dhe nuk gjeneron fusha
              elektromagnetike të dëmshme.
            </p>
          </div>
        </div>
      </section>


      <section className='about-us-contact'>
        <div className='about-us-contact-container'>
          <h1>Keni nje projekt ?</h1>
          <h1>Na Kontaktoni </h1>

          <Link className='contact-us-link' to={'/contact-us'}>
            <PrimaryButton title={"Contact us"} />
          </Link>
        </div>
      </section>
    </main>
  );
};

export default AboutUsPage;
