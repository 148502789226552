import React from 'react'
import {IoLogoFacebook, IoLogoInstagram, IoLogoTwitter, IoLogoYoutube} from 'react-icons/io5'
import {FaPhoneAlt} from 'react-icons/fa'
import {HiOutlineMail} from 'react-icons/hi'
import MapSistemeNgrohje from '../map';
import { Link } from 'react-router-dom';
import certifikimet from '../../assets/certifikimet.pdf'
export const Footer = () => {

   const onCertifikimeDownload = () => {
    const pdfUrl = certifikimet;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "certifikimetThermaltechnology.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
  return (
    <footer className="relative bg-gray-300 pt-8 pb-6 ">
            <div className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20" style={{ height: "80px" }}>

      
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold">
              Let's keep in touch!
            </h4>
            <h5 className="text-lg mt-0 mb-2 text-gray-700">
              Na gjeni ne cdo social media !
            </h5>
            <div className="mt-6 flex mb-6">
              <a href='https://www.facebook.com/sistemngrohje'
                className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 "
                type="button"
              >
                <IoLogoFacebook size={25}/>
              </a>
              <a href='https://www.instagram.com/thermal_technology_albania_/'
                className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 "
                type="button"
              >
                <IoLogoInstagram size={25}/>
              </a>
             
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Useful Links
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://www.creative-tim.com/presentation">About Us
                    </a>
                  </li>
                  <li>
                    <Link className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        to="/categories/radiatore">Produktet
                    </Link>
                  </li>
                 
                  <li>
                    <Link className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        to='/contact-us'>Location
                    </Link>
                  </li>
                  <li>
                    <span onClick={onCertifikimeDownload} className="text-gray-700 cursor-pointer hover:text-gray-900 font-semibold block pb-2 text-sm"
                       >Certifikimet
                    </span>
                  </li>

                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Contact us
                </span>
                <ul className="list-unstyled">
                    <li className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                   >Licence by ThermalAlbania
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="/">Terms & Conditions
                    </a>
                  </li>
                    <li className="text-gray-700 hover:text-gray-900 font-semibold flex items-center  py-2 text-sm"
                       >

                          <FaPhoneAlt/> +355 68 902 6474
                    </li>
                    <li className="text-gray-700 hover:text-gray-900 font-semibold flex gap-1 items-center py-2 text-sm"
                       ><HiOutlineMail /> althermaltt@yahoo.com

                  </li>
                  
                </ul>

              </div>
            
              
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "}by ThermalAlbania{" "}
              <a
                href="https://www.creative-tim.com"
                className="text-gray-600 hover:text-gray-900"
              >
                E.M . All rights reserved
              </a>.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;