import React from 'react';
import './PrimaryButton.css';
const PrimaryButton = ({ onClick, title }) => {
  return (
    <button className='cart-actions-button' onClick={onClick}>
      {title}
    </button>
  );
};

export default PrimaryButton;